import { getDiamondValue, isVisible } from './utils'
import { api } from './utils/db'
import { defineStore } from 'pinia'
import { useGtm } from '@gtm-support/vue-gtm'

async function sleep(ms: number = 1000): Promise<void> {
	return new Promise(resolve => setTimeout(resolve, ms));
}
let gtm = useGtm()

function getFormStep() {
	const sliders = document.querySelectorAll('[form-step]')
	if (!sliders) return 0
	const allSlides = Array.from(sliders)
	const visibleElement = allSlides.filter(el => isVisible(el as HTMLElement)).shift()
	if (!visibleElement) return ''
	const formStep = visibleElement.getAttribute('form-step')
	return formStep
}

export interface FormT {
	[key: string]: any
	item?: string
	certificate_type?: string
	isUnqualified?: boolean
}
function getResetForm(): FormT {
	return {
		form_name: 'Main Form',
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
		item: undefined,
		read_privacy_policy: false,
	}
}

export const useStore = defineStore('jewlery', {
	state: () => ({
		isUploading: false,
		stepIndex: 0,
		lastStep: false,
		stepID: '',
		name: '',
		form: getResetForm(),
		submitting: false,
		submitID: '',
	}),
	getters: {
		is49CaratRing(state): boolean {
			return state.form.item === 'Ring' && state.form.carat === '0-0.49 ct.'
		},

		isUnqualifiedDiamond(state): boolean {
			return (
				state.form.item === 'Diamond' &&
				['carat_range', 'color', 'clarity'].every(key => state.form[key]) &&
				state.form.carat_range === '0-0.49 ct.'
			)
		},
		is49CaratGemstone(state): boolean {
			return state.form.item === 'Gemstone' && state.form.carat_range === '0-1.49 ct.'
		},

		isLessThanHalfCaratJewelry(state): boolean {
			return ['Bracelet', 'Necklace', 'Earring', 'Brooch'].includes(state.form.item ?? '') && +state.form.carat < 0.5
		},
		isUnqualifiedCertType(state): boolean {
			return (
				['AIG', 'GemReport Antwerp (gra)', 'GwLab', 'ALGT'].includes(state.form.certificate_type ?? '')
			)
		}
	},
	actions: {
		async preSubmitForm() {
			const item = this.form.item?.toLowerCase().replace(/\s/g, '_')
			if (item && ['diamond', 'ring'].includes(item)) this.form.estimated_value = getDiamondValue(this.form).average
			this.form.form_name = 'Main Form'
			this.form.form_complete = false
			if (this.submitID) {
				await api.updateSubmission(this.submitID, this.form)
			} else {
				this.submitID = await api.postSubmission(this.form)
			}
		},
		async submitForm() {
			if (this.submitting) return
			if (this.isUploading) {
				setTimeout(async () => this.submitForm(), 1000)
				return
			}
			this.submitting = true

			if (this.submitID) {
				this.form.form_complete = true
				return api.updateSubmission(this.submitID, this.form, true)
			}
			else {
				this.submitID = await api.postSubmission(this.form)
			}
		},
		async trackEvent(event: Record<string, any>, trackComplete = true) {
			if (!gtm) gtm = useGtm()
			const { email, phone } = this.form
			if (email) event.email = email
			if (phone) event.phone = phone
			gtm?.trackEvent({ event: 'form_submit', ...event })
			if (trackComplete) gtm?.trackEvent({ ...event, event: 'form_complete' })
			console.log({ event })
		},
		parseCaratRange() {
			if (this.form.carat_range) {
				const [min, max] = this.form.carat_range?.match(/\d?\.?\d+/g) || [null, null]
				this.form.carat_min = Number.isNaN(min) ? null : min
				this.form.carat_max = Number.isNaN(max) ? null : max
			}
		},

		resetForm() {
			Object.keys(this.form).forEach(key => delete this.form[key])
			const resetform = getResetForm()
			Object.assign(this.form, resetform)
		},
		async nextStep() {
			window.scrollTo({ top: 0, behavior: 'smooth' })
			this.parseCaratRange()
			this.checkUnqualified()
			this.stepIndex++
			await sleep()
			const formStep = getFormStep()
			const item = this.form.item?.toLowerCase().replace(/\s/g, '_')
			const event: Record<string, any> = {
				form_name: `${item}_funnel`,
				form_type: 'main_form_submit',
				form_step: `${formStep}_step`,
			}
			this.trackEvent(event, false).catch(console.error)
			if (event.form_step === 'submit_photos_step') {
				const completeEvent = {
					form_name: `${item}_funnel`,
					event: 'info_complete',
				}				
				this.trackEvent(completeEvent, false).catch(console.error)
			}
		},
		checkUnqualified() {
			if (
				this.is49CaratRing ||
				this.is49CaratGemstone ||
				this.isLessThanHalfCaratJewelry ||
				this.isUnqualifiedDiamond ||
				this.isUnqualifiedCertType
			) {
				this.form.isUnqualified = true
			}
		},
		prevStep() {
			this.stepIndex--
		},
		setName(name: string) {
			this.name = name
		},
	},
})
