import {
	type BglFormSchemaT,
	type Field,
	TelInput,
	TextInput,
	bagelFormUtils
} from '@bagelink/vue'

import { diamondCaratRanges, diamondCertificateTypes, diamondClarities, diamondColors, diamondShapes, gemstoneCaratRanges, gemstoneCertificateTypes, gemstoneShapes } from '@/utils/constants'

import { $t } from '@/lang'

import CustomFileUpload from '@/components/CustomFileUpload.vue'
import FormStep from '@/components/FormStep.vue'
import LocalLink from '@/components/LocalLink.vue'
import OptionButtons from '@/components/OptionButtons.vue'
import Summary from '@/components/Summary.vue'
import Unqualified from '@/components/Unqualified.vue'

export function btnField(
	id: string,
	label?: string,
	options?: (Record<string, any> | string)[],
	config?: Record<string, any>
): Field {
	return {
		$el: OptionButtons,
		id,
		options,
		class: config?.class,
		placeholder: config?.placeholder,
		required: config?.required,
		label,
		defaultValue: config?.defaultValue,
		onUpdate: config?.onUpdate,
		attrs: {
			disabled: config?.disabled,
			asArray: config?.asArray,
			hidename: config?.hidename,
			multiselect: config?.multiselect,
		},
	}
}

export function telField(id: string, label?: string, options?: Record<string, any>): Field {
	return {
		$el: TelInput,
		id,
		label,
		required: options?.required,
		attrs: options,
	}
}

const { txtField, numField, frmRow, checkField } = bagelFormUtils

export function sellFormSchema() {
	const jewlDiamondShapeOptions: BglFormSchemaT = [
		{
			'v-if': (_: any, data: Record<string, any>) => !data.brand,
			'$el': FormStep,
			'attrs': {
				formStep: 'shape',
				title: $t('form.WhatShapeOfLargestDiamond'),
				subtitle: $t('form.selectAnOption'),

			},
			children: [
				btnField('shape', $t('form.diamondShape'), diamondShapes),
			],
		},
	]

	const personalDetails: BglFormSchemaT = [
		{
			'v-if': (_: any, form: Record<string, any>) => !form.isUnqualified,
			$el: FormStep,
			class: 'col w600',
			attrs: {
				formStep: 'registration',
				title: $t('form.personalDetails'),
				subtitle: $t('form.enterYourInfoAndSeeHowMuchYouCanGet'),
			},
			children: [
				{
					$el: Summary,
					attrs: {
						details: (_: any, details: Record<string, any>) => details,
						item: (_: any, val: any) => val.item,
					},
				},
				{
					$el: 'form',
					children: [
						frmRow(
							txtField('first_name', $t('form.firstName'), { required: true }),
							txtField('last_name', $t('form.lastName'), { required: true }),
						),
						txtField('email', $t('form.email'), { type: 'email', required: true, pattern: '^[a-z_A-Z0-9\\-\\.\\+]{1,}@[a-zA-Z0-9\\-\\.]{1,}\\.[a-zA-Z]{2,}$' }),
						telField('phone', $t('form.phoneNumber'), { required: true, class: 'mb-0' }),
						{
							'v-if': (_: any, form: Record<string, any>) => form.item === 'Multiple Items',
							$el: TextInput,
							id: 'comment',
							label: $t('form.description'),
							required: true,
							attrs: {
								multiline: true,
								class: 'mt-1'
							},
						},
						{
							class: 'flex gap-025 justify-content-center mt-1 align-items-top m_flex m_align-items-top m_flex-wrap',
							children: [
								checkField('read_privacy_policy', $t('form.iAgreeToThe'), { required: true, class: 'mb-0' }),
								{
									$el: LocalLink,
									attrs: {
										to: '/legal/terms-and-conditions',
										target: '_blank',
										class: 'nowrap',
									},
									children: [
										$t('form.termsAndConditions'),
									],
								},
							]
						}
					],
					attrs: {
						frm: 'frm-personal-details',
					},
				},
			]
		}
	]

	const imageFields: BglFormSchemaT = [
		{
			'v-if': (_: any, form: Record<string, any>) => !form.isUnqualified,
			$el: FormStep,
			attrs: {
				formStep: 'submit_photos',
				title: $t('sell.pleaseInsertYourPhotosHere'),
				subtitle: $t('sell.optional'),
				class: 'File-Upload-boxs',
			},
			children: [
				{
					$el: CustomFileUpload,
					id: 'images',
					placeholder: $t('form.uploadJewelryPhotos'),
					defaultValue: false,
					attrs: { uploadIcon: 'image' },
				},
				{
					$el: CustomFileUpload,
					id: 'certificate',
					placeholder: $t('form.uploadJewelryPhotos'),
					defaultValue: false,
					attrs: { uploadIcon: 'certificate' },
				},
			]
		}
	]
	const unqualified: BglFormSchemaT = [
		{
			'v-if': (_: any, form: Record<string, any>) => form.isUnqualified,
			'$el': Unqualified,
			'id': 'unqualified',
		},
	]

	const openDiamondCarat: BglFormSchemaT = [
		{
			'v-if': (_: any, form: Record<string, any>) => !form.brand,
			'$el': FormStep,
			'attrs': {
				formStep: 'carat',
				title: $t('form.whatIsTheCaratWeightOfTheDiamond'),
				subtitle: $t('form.enterTheLargestDiamondSCaratWeight'),
			},
			children: [
				numField('carat', $t('form.caratWeight'), { min: 0.5, step: 0.01 }),
				btnField('carat_range', $t('form.caratWeight'), [{ name: $t('form.notSure'), value: 'Other' }]),
			],
		},
	]

	const totalNumberOfDiamonds: BglFormSchemaT = [
		{
			'v-if': (_: any, form: Record<string, any>) => !form.brand,
			'$el': FormStep,
			'attrs': {
				formStep: 'total_diamond_count',
				title: (_: any, data: { item: 'Bracelet' | 'Necklace' }) => (data.item === 'Bracelet' ?
					$t('form.howManyDiamondsInYourBracelet') :
					$t('form.howManyDiamondsInYourNecklace')),
				subtitle: $t('form.countTheNumberOfDiamondsOnTheJewlAndEnterBelow'),

			},
			children: [
				numField('total_diamond_count', $t('form.numberOfDiamonds'), { min: 1, step: 1 }),
			],
		},
	]

	const totalCaratWeight: BglFormSchemaT = [
		{
			'v-if': (_: any, form: Record<string, any>) => !form.brand,
			'$el': FormStep,
			'attrs': {
				formStep: 'carat',
				title: $t('form.whatIsTheCaratWeightOfTheDiamonds'),
				subtitle: $t('form.totalCaratWeightJewl'),

			},
			children: [
				numField('total_carat_weight', $t('form.caratWeight'), { min: 0.5, step: 0.01 }),
				{
					$el: OptionButtons,
					id: 'carat_range',
					options: [{ name: $t('form.notSure'), value: 'Other' }],
				}
			],
		},
	]

	// path flows
	const watchFlow: BglFormSchemaT = [
		{
			$el: FormStep,
			attrs: {
				formStep: 'brand',
				title: $t('form.whatIsTheBrandOfYourWatch'),
				subtitle: $t('form.selectAnOption'),

			},
			children: [
				btnField('brand', $t('form.watchBrand'), [
					{ name: 'Rolex', image: '/img/Rolex.svg' },
					{ name: 'Patek-Philippe', image: '/img/Patek-Philippe.svg' },
					{ name: 'Audemars piguet', image: '/img/audemars-piguet.png' },
					{ name: 'Omega', image: '/img/Omega.svg' },
					{ name: 'Cartier', image: '/img/Cartier.svg' },
					{ name: 'IWC', image: '/img/IWC.svg' },
					{ name: 'Breguet', image: '/img/Breguet.svg' },
					{ name: 'Blancpain', image: '/img/Blancpain.svg' },
					{ name: 'Vacheron Constantin', image: '/img/Vacheron-constantin.svg' },
					{ name: 'Richard Mille', image: '/img/Richard-mille.svg' },
					{ name: 'A. Lange & Söhne', image: '/img/Alange-soehne.svg' },
					{ name: $t('form.other'), value: 'Other' },
				], { hidename: true }),
			]
		},
		{
			$el: FormStep,
			attrs: {
				formStep: 'certificate_exists',
				title: $t('form.doYouHaveDocuments'),
				subtitle: $t('form.selectAnOption'),

			},
			children: [
				btnField('certificate_exists', $t('form.doYouHaveDocuments'), [
					{ name: $t('form.yes'), value: true },
					{ name: $t('form.no'), value: false },
				]),
			],

		},
		{
			$el: FormStep,
			attrs: {
				formStep: 'watch_box',
				title: $t('form.doYouHaveTheOriginalBox'),
				subtitle: $t('form.selectAnOption'),

			},
			children: [
				btnField('original_box_exists', $t('form.doYouHaveTheOriginalBox'), [
					{ name: $t('form.yes'), value: true },
					{ name: $t('form.no'), value: false },
				],),
			],
		},
		...personalDetails,
		...imageFields,
	]

	const caratpicker: BglFormSchemaT = [
		{
			$el: FormStep,
			attrs: {
				formStep: 'carat',
				title: $t('form.whatIsTheCaratWeightOfYourDiamond'),
				subtitle: $t('form.selectAnOption'),
			},
			children: [
				btnField('carat_range', $t('form.caratWeight'), diamondCaratRanges),
			],
		},
	]

	const diamondFlow: BglFormSchemaT = [
		{
			$el: FormStep,
			attrs: {
				formStep: 'shape',
				title: $t('form.whatIsTheShapeOfYourDiamond'),
				subtitle: $t('form.selectAnOption'),

			},
			children: [
				btnField('shape', $t('form.diamondShape'), diamondShapes),
			],
		},
		...caratpicker,
		{
			'v-if': (_: any, data: Record<string, any>) => data.carat_range !== 'Other',
			'$el': FormStep,
			'attrs': {
				formStep: 'color',
				title: $t('form.excellentWhatIsTheColorOfYourDiamond'),
				subtitle: $t('form.selectAnOption'),

			},
			children: [btnField('color', $t('form.diamondColor'), diamondColors, { asArray: true })]
		},
		{
			'v-if': (_: any, data: Record<string, any>) => data.carat_range !== 'Other',
			'$el': FormStep,
			'attrs': {
				formStep: 'clarity',
				title: $t('form.whatIsTheClarityOfYourDiamond'),
				subtitle: $t('form.selectAnOption'),

			},
			children: [btnField('clarity', $t('form.diamondClarity'), diamondClarities, { asArray: true }),]
		},
		{
			'v-if': (_: any, data: Record<string, any>) => data.carat_range !== 'Other',
			'$el': FormStep,
			'attrs': {
				formStep: 'certificate_type',
				title: $t('form.greatDoYouHaveACertificateForYourDiamondIfSoWhatKind'),
				subtitle: $t('form.selectAnOption'),

			},
			children: [btnField('certificate_type', $t('form.diamondCertificate'), diamondCertificateTypes)]
		},
		...unqualified,
		...personalDetails,
		...imageFields,
	]

	const gemstoneFlow: BglFormSchemaT = [
		{
			$el: FormStep,
			attrs: {
				formStep: 'shape',
				title: $t('form.whatIsTheShapeOfYourGemstone'),
				subtitle: $t('form.selectAnOption'),

			},
			children: [
				btnField('shape', $t('form.gemstoneShape'), gemstoneShapes),
			]
		},
		{
			$el: FormStep,
			attrs: {
				formStep: 'gemstone_type',
				title: $t('form.whatIsTheTypeOfYourGemstone'),
				subtitle: $t('form.selectAnOption'),

			},
			children: [
				btnField('gemstone_type', $t('form.gemstoneType'), [
					{ name: $t('form.sapphire') },
					{ name: $t('form.emerald') },
					{ name: $t('form.ruby') },
					{ name: $t('form.notSureOther'), value: 'not sure' },
				])
			],
		},
		{
			$el: FormStep,
			attrs: {
				formStep: 'carat',
				title: $t('form.whatIsTheCaratWeightOfYourGemstone'),
				subtitle: $t('form.selectAnOption'),

			},
			children: [
				btnField('carat_range', $t('form.caratWeight'), gemstoneCaratRanges),
			],
		},
		{
			$el: FormStep,
			attrs: {
				formStep: 'certificate_type',
				title: $t('form.doYouHaveACertificate'),
				subtitle: $t('form.selectAnOption'),

			},
			children: [
				btnField('certificate_type', $t('form.gemstoneCertificate'), gemstoneCertificateTypes),
			],
		},
		...unqualified,
		...personalDetails,
		...imageFields,
	]

	const jewlBrand: BglFormSchemaT = [
		{
			$el: FormStep,
			attrs: {
				formStep: 'brand',
				title: $t('form.whatIsTheBrandOfYourBracelet'),
				subtitle: $t('form.selectAnOption'),
				hidename: true,

			},
			children: [
				btnField('brand', $t('form.jewelryBrand'), [
					{ name: 'Cartier', image: '/img/Cartier.svg' },
					{ name: 'Van Cleef & Arpels', image: '/img/Van-Cleef-Arpels.svg' },
					{ name: 'Bvlgari', image: '/img/Bulgari.svg' },
					{ name: 'Tiffany & Co.', image: '/img/Tiffany-Co.svg' },
					{ name: 'Chopard', image: '/img/Chopard.svg' },
					{ name: 'Harry Winston', image: '/img/Harry-Winston.svg' },
					{ name: 'Graff', image: '/img/Graff.svg' },
					{ name: $t('form.notSureOther'), value: '' },
				], { hidename: true }),
			],
		},
	]

	const braceletFlow: BglFormSchemaT = [
		...jewlBrand,
		...totalNumberOfDiamonds,
		...totalCaratWeight,
		...unqualified,
		...personalDetails,
		...imageFields,
	]

	const ringFlow: BglFormSchemaT = [
		...jewlDiamondShapeOptions,
		...caratpicker,
		...unqualified,
		...personalDetails,
		...imageFields,
	]
	const necklaceFlow: BglFormSchemaT = [
		...jewlBrand,
		...totalNumberOfDiamonds,
		...totalCaratWeight,
		...unqualified,
		...personalDetails,
		...imageFields,
	]
	const earringFlow: BglFormSchemaT = [
		...jewlBrand,
		...jewlDiamondShapeOptions,
		...openDiamondCarat,
		...unqualified,
		...personalDetails,
		...imageFields,
	]
	const broochFlow: BglFormSchemaT = [
		...jewlBrand,
		...jewlDiamondShapeOptions,
		...openDiamondCarat,
		...unqualified,
		...personalDetails,
		...imageFields,
	]

	const multiFlow: BglFormSchemaT = [
		...personalDetails,
		...imageFields,
	]

	return {
		multiFlow,
		braceletFlow,
		broochFlow,
		diamondFlow,
		earringFlow,
		gemstoneFlow,
		necklaceFlow,
		ringFlow,
		watchFlow,
	}
}
