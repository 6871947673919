<script setup lang="ts">import { computed as _computed, ref as _ref } from 'vue';

import { useRoute } from 'vue-router'
import BotFooter from '@/components/BotFooter.vue'
import TopNav from '@/components/TopNav.vue'
import Hero from '@/blockBuilder/Blocks/Hero.vue'
import RichText from '@/blockBuilder/Blocks/RichText.vue'
import Image from '@/blockBuilder/Blocks/Image.vue'
import TextSideImage from '@/blockBuilder/Blocks/TextSideImage.vue'
import CatalogSlider from '@/components/CatalogSlider.vue'
import PageButton from '@/blockBuilder/Blocks/PageButton.vue'
import Popups from '@/blockBuilder/Blocks/Popups.vue'
import Line from '@/blockBuilder/Blocks/Line.vue'
import Header from '@/blockBuilder/Blocks/Header.vue'
import { sellPageBlocks } from '@/data/sellPageBlocks'
import { langFromPath } from '@/lang'
import { watch } from 'vue'


const blockComponents: Record<string, any> = {
	Hero,
	RichText,
	Image,
	TextSideImage,
	PageButton,
	Popups,
	Header,
	Line,
	CatalogSlider,
}

const route = useRoute()

const pageLang = _computed(() => langFromPath(route.path))
const pathToSlug = (path: string) => path.split('/').filter(Boolean).pop()

let page = _ref<Record<string, any>>()

function loadPage() {
	const routeSlug = pathToSlug(route.path)
	page.value = sellPageBlocks.find((page: any) => page.slug === routeSlug && page.language?.value === pageLang.value)
}

watch(() => route.path, loadPage, { immediate: true })
</script>

<template>
	<div class="act_bg-white">
		<TopNav />
		<div class="page-wrap act_w1170">
			<template v-for="(comp, i) in page?.components" :key="comp.id">
				<component v-if="comp" :is="blockComponents[comp.type]" v-bind="comp.data" />
			</template>
		</div>
		<BotFooter />
	</div>
</template>

<style>
.act_bg-white .top-nav-wrapper {
	background-color: var(--blue-dark);
}
.act_bg-white {
	background: white;
}
.act_bg-white .page-wrap {
	color: #0b2335;
}
.act_bg-white .home-carousel-item {
	border: 1px solid #b7b7b7;
}
.highlight, .editMode:hover {
	animation: highlighter 1.5s ease-in-out forwards;
	outline: 1px solid #58bfeb;
	background: #58bfeb20;
}
.compName{
	opacity: 0;
	background: #58bfeb;
	 border-top-left-radius: 5px;
	 border-top-right-radius: 5px;

}
.highlight .compName{
	opacity: 1 !important;
}
@keyframes highlighter {
	to {
		/* outline: 1px solid #58bfeb00; */
		background: #58bfeb00;

	}
}
</style>
