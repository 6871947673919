import axios from 'axios'
import { i18n } from '../lang'
import { Bagel } from './BagelDB'
import { getCookie, getIp, getSessionStorage } from '.'
import type { FormSubmissionBase } from '@/client/types'

const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImFwaS1rZXkiLCJ0eXAiOiJKV1QifQ.eyJvcmdhbml6YXRpb25JRCI6ImNpdjI0amEyM2FrZzAwODNwY3QwIiwicHJvamVjdElEIjoiY24wMm5ncTIzYWtnMDA5YW1iZDAiLCJhdWQiOiJodHRwczovL2FwcC5iYWdlbGRiLmNvbSIsImp0aSI6IjRhZGFiMDUyLTlmZDktNDg3Ni1hZDk5LThjZDBjMTEyYjMzYSIsImlzcyI6Imh0dHBzOi8vYXV0aGVudGljYXRpb24uYmFnZWxkYi5jbyIsInN1YiI6ImNuMDJuZ3EyM2FrZzAwOWFtYmQwIn0.oaG1G5U4vEWx_3fs-K4qaJAvyWzwUWm_w3YQ84MRuFJcGNpPJEGxOGs2FDc912-u4cxed-qllbxalF44m1JgGNKOEj6WddmbYphF8X_U6Hd_ghXM9KIR3yfrT1q_KL3ZIVuEJis59uHHz9XaZ3YZW5Qhucw8coRwmpS96gnvOO2CpzMgwC4woazr2RmUQyv6YoZftHqoMAG8adMDMm7YVjCwqdYav7lbi1fL94bErE73qFtjFvOyh4aniAHYuwksy3kVFddpaJSw_0nIQSX9xmyhd5ZHnaUX3LTWmldPDgTYoynOXVaGJBltNrL4VrDOrszec8y3mBO-Llj7lPBIM2A4aNLls5W2MAtm_JNdaohbqrRcwph9Q9wc-REb9ZlF3HnBw6bE2RYlKujDdpBXgDs9C-FMTgkDoNtu94khzZ-nGUqNPqmXp9wO7_F8ECTou2U8KjDPfn-bhvuI3Rjm_2iagKPEFHg7Lx15pH3xgLUspa8T_ROGchdD2CfGDprEJHNlyIX_aN8fmGpuXut2OHx7m4auM-2CuvwG2SYauHduvpcVURxh0FT85ujYQx9kMONcDZMN6k9_5y0rp0Fdb7Lu-7yPaDOr42NqkBgmjk-9FVmS_Rnv1Z8pFcL9xsyo-TSSw_uGGpMcrr14F5fG4pJVEjHdEZyar18h-XBlG4c'

export const db = new Bagel(token)

export async function addMetaData(formData: Record<string, any>) {
	if (!formData.ip) formData.ip = (await getIp()).ip
	if (!formData.referrer) formData.referrer = document.referrer
	if (!formData.hubspotutk) formData.hubspotutk = getCookie('hubspotutk') || ''
	if (!formData.query) {
		const query = getSessionStorage('query') ?? {}
		formData.query = query
		Object.keys(query).forEach(key => formData[key] = formData.query[key])
	}
	if (!formData.page_url) formData.page_url = window.location.href
	if (!formData.language) formData.language = i18n.locale.value
	if (!formData.hs_language) formData.hs_language = i18n.locale.value
	return formData as FormSubmissionBase
}

export async function postDB(collection: string, formData: Record<string, any>) {
	formData = await addMetaData(formData)
	if (formData._id) { await db.collection(collection).item(formData._id).put(formData)
	}
	else {
		const res = await db.collection(collection).post(formData)
		formData._id = res.data.id
	}
	return formData
}

export const api = {
	async updateSubmission(formID: string, formData: Record<string, any>, complete_submission = false) {
		formData = await addMetaData(formData)
		const q = complete_submission ? `?complete_submission=${complete_submission}` : ''

		if (formData.total_carat_weight === '') formData.total_carat_weight = undefined

		return axios.put(`${import.meta.env.VITE_BAGEL_BASE_URL}/form/item_submission/${formID}${q}`, formData)
	},
	async postSubmission(formData: Record<string, any>) {
		formData = await addMetaData(formData)

		if (formData.total_carat_weight === '') formData.total_carat_weight = undefined

		const { data } = await axios.post(`${import.meta.env.VITE_BAGEL_BASE_URL}/form/item_submission`, formData)
		return data.id
	}
}
