<script lang="ts" setup>
defineProps<{ image: string, alt: string, align: string }>()
const fallbackImg = 'https://bagel.sfo2.cdn.digitaloceanspaces.com/Bagelink/placeholder-image.svg'
</script>

<template>
	<div class="act_pt-3 act_pb-4 w790 act_mx-auto">
		<img class="full-img act_round" :src="image || fallbackImg" :alt="alt" :class="align">
	</div>
</template>
