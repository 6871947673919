<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { diamondClarities, diamondColors, diamondShapes } from '@/utils/constants'
import { $t } from '@/lang'
import { BagelForm, Btn } from '@bagelink/vue'
import { getDiamondValue } from '@/utils'
import { submitSchema } from '@/schemas/valuateForm'
import { onMounted, watch } from 'vue'


import Modal from '@/components/Modal.vue'
import SmOptionButtons from '@/components/SmOptionButtons.vue'
import Summary from '@/components/Summary.vue'

import { useStore } from '@/store'

const disclaimerOpen = _ref(false)

const submitOpen = _ref(false)
let loadingPriceRange = _ref(false)
const modalImage = _ref('')
let formStatus = _ref('')

const store = useStore()

const priceRange = _computed(() => {
	const { min, max } = getDiamondValue(store.form)
	return { min, max }
})

let bounce: any = null

watch(
	() => priceRange.value.max,
	(newPrice, oldPrice) => {
		if (newPrice === oldPrice || !oldPrice) return
		loadingPriceRange.value = true
		if (bounce) clearTimeout(bounce)
		bounce = setTimeout(() => (loadingPriceRange.value = false), 700)
	},
)
const submitForm = _ref<typeof BagelForm>()

	async function submitInquery() {
	if (!submitForm.value?.validateForm()) return
	store.form.item = 'Diamond'
	store.form.estMin = Math.round(priceRange.value.min)
	store.form.estMax = Math.round(priceRange.value.max)
	store.submitForm()
}

onMounted(() => {
	store.form.carat = 2.5
})
</script>

<template>
	<div class="ValuateWrap">
		<Modal v-model:visible="disclaimerOpen">
			<h3 class="act_center playfair act_no-marg act_txt30 act_pb-1">
				{{ $t('disclaimer.disclaimer') }}
			</h3>
			<p>{{ $t('disclaimer.estimates') }}</p>
			<p>{{ $t('disclaimer.cut') }}</p>
			<p>{{ $t('disclaimer.symmetry') }}</p>
			<p>{{ $t('disclaimer.fluorescence') }}</p>
			<br>
			<p>{{ $t('disclaimer.actual') }}</p>
			<p>{{ $t('disclaimer.precise') }}</p>
		</Modal>
		<Modal
			:visible="!!modalImage"
			class="preview-image"
			title="Image Preview"
			@update:visible="modalImage = ''"
		>
			<img v-if="modalImage" :src="modalImage" alt="preview">
		</Modal>
		<!-- <Modal v-model:visible="vipOpen" class="vip-modal">
			<div class="white-box act_center">
				<h3 v-if="formStatus !== 'success'" class="playfair act_no-marg act_txt30 act_pb-1">
					{{ $t('home.vipCustomers') }}
				</h3>
				<p v-if="formStatus !== 'success'" class="act_pb-1">
					{{ $t('home.transformYourHighValueJewels') }}
				</p>
				<BagelForm
					v-if="formStatus !== 'success'"
					v-model="formData"
					class="submit-modal txt-start"
					:schema="vipFormSchema"
					@submit="submitVipForm"
				>
					<template #submit="{ submit }">
						<button type="button" class="act_btn act_mt-1 act_mx-auto block" @click.stop="submit">
							{{ $t('general.send') }}
						</button>
					</template>
				</BagelForm>
				<div v-if="formStatus === 'err'">
					<p class="form-fail">
						{{ $t('contactUs.error') }}
					</p>
				</div>
				<div v-if="formStatus === 'success'" class="act_pt-3">
					<p class="playfair act_no-marg act_txt30 act_pb-2">
						{{ $t('contactUs.thankYou') }}
					</p>
					<p>
						{{ $t('contactUs.touch') }}
					</p>
				</div>
			</div>
			<img class="vip-modal-img" src="@/assets/vip-bg.webp" alt="VIP">
		</Modal> -->
		<Modal v-model:visible="submitOpen">
			<Summary v-if="formStatus !== 'success'" :details="store.form" item="Diamond" />
			<BagelForm
				v-if="formStatus !== 'success'"
				ref="submitForm"
				v-model="store.form"
				class="submit-modal"
				:schema="submitSchema()"
				@submit="submitInquery"
			>
				<template #submit>
					<button
						type="submit"
						:disabled="store.submitting"
						class="act_btn act_mt-1 act_mx-auto block"
						@click="submitInquery"
					>
						<template v-if="!store.submitting">
							{{ $t('sell.submit') }}
						</template>
						<img v-else width="50" alt="loading" src="/img/loading.svg" class="loading">
					</button>
				</template>
			</BagelForm>
			<div v-if="formStatus === 'err'">
				<p class="form-fail">
					{{ $t('contactUs.error') }}
				</p>
			</div>
			<div v-if="formStatus === 'success'" class="">
				<p class="playfair act_no-marg act_txt30 act_pb-1">
					{{ $t('thankYou.title') }}
				</p>
				<p>
					{{ $t('contactUs.touch') }}
				</p>
			</div>
		</Modal>
		<div class="grid grid-wrap-3 py-3">
			<div class="sticky bg-blue-dark color-white radius-2 m_relative sideInfoBox">
				<div class="txt-center -mt-2 pb-2  m_none">
					<img class="diamond-wiz h-70px" src="@/assets/diamond-small.png" alt="diamond">
				</div>
				<div class="mx-auto">
					<div
						class="flex gap-05 pb-1 mw-200px mx-auto  m_none"
					>
						<p class="">
							{{ $t('sell.shape') }}:
						</p>
						<img
							v-if="diamondShapes.find((s) => s.value === store.form.shape)?.image"
							width="30"
							height="30"
							:src="diamondShapes.find((s) => s.value === store.form.shape)?.image"
							alt="cushion"
						>

						<p v-if="store.form.shape" class="fadein act_start">
							<b>{{ $t(`form.${store.form.shape}`) }}</b>
						</p>
						<p v-else>
							--
						</p>
					</div>
					<div class="flex gap-05 pb-1 mw-200px mx-auto  m_none">
						<p>{{ $t('sell.weight') }}:</p>
						<p v-if="store.form.carat" class="">
							<b>{{ store.form.carat }}<span v-if="store.form.carat !== '--'">ct.</span> </b>
						</p>
					</div>
					<div class="flex gap-05 pb-1 mw-200px mx-auto  m_none">
						<p>{{ $t('sell.clarity') }}:</p>
						<p class="fadein">
							<b>{{ store.form?.clarity?.join(', ') || '--' }}</b>
						</p>
					</div>
					<div class="flex gap-05 pb-1 mw-200px mx-auto  m_none">
						<p>{{ $t('sell.color') }}:</p>
						<p class="fadein">
							<b>{{ store.form.color?.join(',') || '--' }}</b>
						</p>
					</div>
					<!-- <div v-if="Number(store.form.carat) >= 5" class="p-1 m-1 border-primary rounded txt-center">
						<p class="color-white balance pb-05">
							{{ $t('sell.forCustomers') }}:
						</p>
						<Btn class=" m_px-1" @click="vipOpen = !vipOpen">
							{{ $t('home.scheduleYourAppointment') }}
						</Btn>
					</div> -->
					<div>
						<div class="px-4 m_px-025 m_pt-1">
							<div class="line bg-primary my-1  m_none" />
						</div>
						<div class="txt-center">
							<p>{{ $t('sell.priceRange') }}</p>

							<div v-if="loadingPriceRange">
								<img height="55" src="@/assets/loading.svg" alt="loading">
							</div>

							<p v-else-if="priceRange" class="fadein txt-24 pb-1 m-0 m_pb-0">
								<b>€{{ priceRange?.min?.toLocaleString('en-US').split('.').shift() }} - €{{
									priceRange?.max?.toLocaleString('en-US').split('.').shift()
								}}</b>
							</p>
						</div>
						<div class="mx-auto column flex">
							<Btn flat class="color-white txt-14 underline" @click="disclaimerOpen = !disclaimerOpen">
								{{ $t('home.readMore') }}
							</Btn>
							<Btn class="round -mb-1" @click="submitOpen = true">
								{{ $t('home.getTheBestOffer') }}
							</Btn>
						</div>
					</div>
				</div>
			</div>
			<div class="grid-span-2 shapeBtnsWrap pt-2">
				<SmOptionButtons
					v-model="store.form.shape"
					:label="$t('sell.shape')"
					:options="diamondShapes"
				/>
				<div id="step2-Diamond" class="act_w900 pb-3">
					<div class="flex gap-05">
						<h3 class="playfair  txt24 m-0 pb-1 pretty m_txt20">
							{{ $t('sell.carat') }}
						</h3>
						<label class="carat-size playfair txt24 color-gray">
							<input
								v-model="store.form.carat"
								disabled="true"
								type="number"
								:step="0.1"
								:min="0.5"
								:max="5"
							>
							<span> ct. </span>
						</label>
					</div>

					<div class="carat-slide flex align-items-center gap-1">
						<p>0.5</p>
						<input
							v-model="store.form.carat"
							:max="25"
							:min="0.5"
							:step="0.1"
							class="w-100"
							type="range"
						>
						<p>25+</p>
					</div>
				</div>
				<SmOptionButtons
					v-model="store.form.clarity"
					:label="$t('sell.clarity')"
					:options="diamondClarities"
					:asArray="true"
				/>
				<SmOptionButtons
					v-model="store.form.color"
					:label="$t('sell.color')"
					:options="diamondColors"
					:asArray="true"
				/>
			</div>
		</div>
	</div>
</template>

<style scoped>
.mw-200px {
	max-width: 200px;
}
.border-primary {
	color: var(--blue) !important;
}

.fadein {
	animation: fadein 1s;
}

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.carat-slide input {
	-webkit-appearance: none;
	appearance: none;
	height: 12px;
	background: var(--blue);
	outline: none;
	border-radius: 30px;
}

.carat-slide input::-webkit-slider-thumb {
	border-radius: 4px;
	-webkit-appearance: none;
	appearance: none;
	width: 47px;
	height: 23px;
	background: white;
	cursor: pointer;
	transition: all 400ms ease;
	background: url(/public/img/slider-arrows.svg) white;
	background-repeat: no-repeat;
	background-position: center;
	outline: 1px solid var(--blue);
}

.carat-slide input::-webkit-slider-thumb:hover {
	filter: brightness(90%);
}

.carat-slide input::-webkit-slider-thumb:active {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	filter: brightness(80%);
}

.carat-slide input::-moz-range-thumb {
	border-radius: 4px;
	appearance: none;
	width: 47px;
	height: 23px;
	background: white;
	cursor: pointer;
	transition: all 400ms ease;
	background: url(/public/img/slider-arrows.svg) white;
	background-repeat: no-repeat;
	background-position: center;
	outline: 1px solid var(--blue);
}

.carat-slide input::-moz-range-thumb:hover {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
	filter: brightness(90%);
}

.carat-slide input::-moz-range-thumb:active {
	box-shadow: 0 0 30px black;
	filter: brightness(80%);
}
.carat-size {
	background: #ecf1f6;
	border-radius: 10px;
	padding: 0.5rem 1rem 0.75rem;
	transform: translateY(-0.6rem) scale(0.8);
}

.carat-size input {
	border: none;
	width: 60px;
	color: var(--black);
	margin-inline-end: -1rem
}

.carat-size input:focus-visible {
	outline: none;
}
</style>

<style>
.ValuateWrap .valuate-option {
	border-radius: 20px;
	outline: 1px solid var(--blue);
	gap: 0.5rem;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 0.75rem;
	min-width: 50px;
	background: white;
}

.ValuateWrap .valuate-option.wizOptionActive {
	background: var(--blue);
	color: white;
}

.ValuateWrap .valuate-option img {
	height: 18px;
}

.ValuateWrap .valuate-option.wizOptionActive img {
	filter: brightness(0) invert(1);
}
.ValuateWrap .valuate-options-wrap {
	gap: 0.75rem;
}
@media screen and (max-width: 900px) {
	.shapeBtnsWrap .valuate-option {
        height: 36px;
        padding: 0.5rem 0.75rem;
        font-size: 14px;
		flex-shrink: 0;    }
.shapeBtnsWrap .valuate-options-wrap{
	padding: 0.25rem;
	flex-wrap: nowrap;
	overflow-x: auto;
	gap: 0.25rem;
	mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%);
	-webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%);

}
.shapeBtnsWrap .act_w900{
	width: 100%;
}
.shapeBtnsWrap .act_pb-3, .shapeBtnsWrap .pb-3{
	padding-bottom: 1rem !important;
}
.shapeBtnsWrap ::-webkit-scrollbar{
	display: none !important;
}

.shapeBtnsWrap{
order: 1;
}
.sideInfoBox{
	order: 2;
}
}
</style>
