import axios from 'axios'
import { createHead } from '@vueuse/head'
import { i18n } from '../lang'

export function titleCase(str: string) {
	if (!str) return ''
	return str.toLowerCase().split(' ').map(word => word.replace(word[0], word[0].toUpperCase())).join(' ')
}

export function isVisible(elem: HTMLElement): boolean {
	if (!(elem instanceof Element)) throw new Error('DomUtil: elem is not an element.')

	// Get computed style
	const style = getComputedStyle(elem)
	if (style.display === 'none' || style.visibility !== 'visible' || Number.parseFloat(style.opacity) < 0.1) {
		return false
	}

	// Check dimensions
	if (elem.offsetWidth + elem.offsetHeight + elem.getBoundingClientRect().height + elem.getBoundingClientRect().width === 0) {
		return false
	}

	// Helper function to check if an element is obscured
	function isObscured(element: HTMLElement, x: number, y: number): boolean {
		const topElement = document.elementFromPoint(x, y)
		return topElement === element || element.contains(topElement as Node)
	}

	// Get element's bounding box
	const rect = elem.getBoundingClientRect()

	// Define points to check (corners and center)
	const points = [
		{ x: rect.left, y: rect.top }, // top-left
		{ x: rect.right, y: rect.top }, // top-right
		{ x: rect.left, y: rect.bottom }, // bottom-left
		{ x: rect.right, y: rect.bottom }, // bottom-right
		{ x: rect.left + elem.offsetWidth / 2, y: rect.top + elem.offsetHeight / 2 }, // center
	]

	for (const point of points) {
		if (point.x >= 0 && point.x <= (window.innerWidth || document.documentElement.clientWidth) &&
			point.y >= 0 && point.y <= (window.innerHeight || document.documentElement.clientHeight)) {
			if (isObscured(elem, point.x, point.y)) {
				return true
			}
		}
	}

	return false
}

import fantasyPricing from '../data/fantasyPricing.json'
import roundPricing from '../data/roundPricing.json'
import { conversion_rates } from '../data/exchangerate.json'

function parseCarat(c?: any) {
	if (!c) return 0
	if (typeof c === 'number') return c
	if (typeof c === 'string') {
		const arr = c.split('-').map((c: string) => Number.parseFloat(c)).filter(n => !Number.isNaN(n))
		const sum = arr.reduce((acc, val) => acc + val, 0)
		const average = sum / arr.length
		return average
	}
	return 0
}

const clarityList = ['IF', 'VVS1', 'VVS2', 'VS1', 'VS2', 'SI1', 'SI2', 'SI3', 'I1'] // I2, I3
const shapeList = ['Round', 'Pear', 'Marquise', 'Emerald', 'Princess', 'Oval', 'Heart', 'Radiant', 'Cushion', 'Asscher', 'Baguette', 'Trillion']

export function getDiamondValue(inquery: Record<string, any>) {
	const markdown = {
		min: 0.25,
		max: 0.45,
	}
	let { clarity, color, shape } = inquery
	clarity = clarity?.[0]
	color = color?.[0]
	const carat = parseCarat(inquery.carat)
	if (!carat || Number.isNaN(carat)) return { min: 0, max: 0, average: 0 }

	let priceList: Record<string, any>[] = []
	if (!shapeList.includes(shape)) { priceList.push(...roundPricing, ...fantasyPricing)
	}
	else if (shape === 'Round') {
		priceList.push(...roundPricing)
		markdown.min = 0.3
	} else { priceList.push(...fantasyPricing)
	}
	const colorList = [...new Set(priceList.map(p => p.color))]
	if (carat) priceList = priceList.filter((p: any) => carat >= p.ctMin && carat <= p.ctMax)
	if (color && colorList.includes(color)) priceList = priceList.filter((p: any) => p.color === color)
	if (clarityList.includes(clarity)) priceList = priceList.filter((p: any) => p.clarity === clarity)
	else priceList = priceList.filter((p: any) => clarityList.includes(p.clarity))
	const minPrice = Math.min(...priceList.map((p: any) => +p.price))
	const maxPrice = Math.max(...priceList.map((p: any) => +p.price))
	const min = (minPrice * carat * markdown.min) / conversion_rates.USD
	const max = (maxPrice * carat * markdown.max) / conversion_rates.USD
	const average = Math.round((min + max) / 2)
	return { min, max, average }
}

export async function getIp() {
	const apiData = getSessionStorage('ipapi')
	if (apiData) return apiData
	const { data } = await axios.get('https://ipapi.co/json/')
	console.log(data)
	setSessionStorage('ipapi', JSON.stringify(data))
	return data
}

const head = createHead()
export function setHead(title?: string, description?: string, ogImage?: string) {
	head.push(
		{
			title: `${title} | Auctentic`,
			meta: [
				{ name: 'description', content: description },
				{ property: 'og:title', content: `${title} | Auctentic` },
				{ property: 'og:description', content: description },
				{ property: 'og:image', content: ogImage },
				{ property: 'og:type', content: 'article' },
				{ property: 'og:locale', content: i18n.locale.value },
				{ property: 'og:site_name', content: 'Auctentic' },
			],
		},
	)
}

export function getCookie(cookieName: string) {
	const cookies: Record<string, string> = {}
	const split = document.cookie.split(';')
	for (const c of split) {
		if (c) {
			const [key, value] = c.split(/=/)
			if (key && value) {
				cookies[key.trim()] = value.trim()
			}
		}
	}
	return cookies[cookieName]
}

export function getSessionStorage(key: string) {
	try {
		const item = sessionStorage.getItem(key)
		if (item !== null && item !== undefined && item !== '') {
			try {
				const obj = JSON.parse(item)
				return obj
			} catch (err) {
				console.error('Failed to parse sessionStorage:', err)
				return item
			}
		}
	} catch (error) {
		console.error('Failed to access sessionStorage:', error)
	}
	return null
}

export function setSessionStorage(key: string, value: any) {
	if (value === null || value === undefined) {
		sessionStorage.removeItem(key)
		return
	}
	try {
		const item = typeof value === 'string' ? value : JSON.stringify(value)
		sessionStorage.setItem(key, item)
	} catch (error) {
		console.error('Failed to set sessionStorage:', error)
	}
}
