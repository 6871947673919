<script setup lang="ts">import { computed as _computed, ref as _ref } from 'vue';

import { useRouter } from 'vue-router'
import { type SwiperContainer, register } from 'swiper/element/bundle'
import 'swiper/css'
import { onMounted, watch } from 'vue'
import type { SwiperOptions } from 'swiper/types'
import { products } from '@/data/products'
import { useLangTools } from '@/lang'

const props = defineProps<{ index?: number, showTypes?: string[] }>()
const { $tdb } = useLangTools(useRouter())
register()

const imgProducts = _computed(() => {
	const showOnly = props.showTypes?.length ?
		props.showTypes :
		['Rings', 'Diamonds', 'Necklaces', 'Bracelets', 'Earrings', 'Watches']
	return products.filter(
		product => product.imageGallery.length && showOnly.includes(product.tape.value),
	)
})

interface ImageObj {
	imageName: string
	altText: string
	imageURL: string
	extension: string
}

function previewImage(imageGallery: ImageObj[]) {
	const onlyImages = imageGallery.filter(image => ['.jpeg', '.png', '.jpg', '.webp'].includes(image.extension),
	)
	return onlyImages[0]?.imageURL
}

const swiperEl = _ref<SwiperContainer | null>(null)

const swiperParams: SwiperOptions = {
	effect: 'coverflow',
	grabCursor: true,
	centeredSlides: true,
	slidesPerView: 'auto',
	navigation: true,
	initialSlide: 0, // middle slide
	// initialSlide: ,
	loop: true,
	// cssMode: true,
	coverflowEffect: {
		rotate: -2,
		stretch: 0,
		depth: 100,
		// modifier: 1,
		slideShadows: true,
		modifier: 1,
		scale: 0.98,
	},
	on: {
		init() {},
	},
}

function handleSlideNav(dir: 'prev' | 'next') {
	const { nextEl, prevEl } = swiperEl.value?.swiper.navigation || {}

	if (dir === 'next') {
		nextEl?.click()
		return
	}

	prevEl?.click()
}

onMounted(() => {
	Object.assign(swiperEl.value as SwiperContainer, {
		...swiperParams,
		// navigation: {
		// 	nextEl,
		// 	prevEl,
		// },
	})

	swiperEl.value?.initialize()
})

watch(
	() => props.index,
	(index) => {
		if (index !== undefined) {
			setTimeout(() => swiperEl.value?.swiper.slideTo(index), 1)
		}
	},
	{ immediate: true },
)
</script>

<template>
	<div class="act_center act_pt-3 act_pb-4">
		<h3 class="playfair act_txt40 act_no-marg relative">
			{{ $t('home.recentlySold') }}
		</h3>
		<div class="swi-wrap">
			<swiper-container ref="swiperEl" class="swiper mySwiper" init="false">
				<swiper-slide
					v-for="product in imgProducts"
					:key="product._id"
					class="home-carousel-item act_start"
				>
					<LocalLink
						:title="$t('home.sold')"
						class="hover decoration-none"
						:to="`/recently-sold/${$tdb(product, 'slug')}`"
					>
						<p class="sold">
							{{ $t('home.sold') }}
						</p>
						<img
							class="carousel-img"
							width="320"
							height="330"
							loading="lazy"
							:src="previewImage(product.imageGallery)"
							alt="placeholder"
						>
						<div class="home-carousel-item-txt">
							<div class="act_flex act_gap-05 playfair align-items-flex-end">
								<p class="currency">
									€
								</p>
								<p class="act_txt30">
									{{ product.salePrice?.toLocaleString('en-US') }}
								</p>
							</div>
							<p class="home-carosel-small-txt ellipsis">
								{{ $tdb(product, 'name') }}
							</p>
						</div>
					</LocalLink>
				</swiper-slide>
			</swiper-container>
			<div class="swi-ctrl">
				<div class="swi-prev hover" @click="handleSlideNav('prev')">
					<img src="../assets/arrow-left.svg" alt="arrow left">
				</div>
				<div class="swi-next hover" @click="handleSlideNav('next')">
					<img src="../assets/arrow-right.svg" alt="arrow right">
				</div>
			</div>
		</div>
		<LocalLink to="/recently-sold" :title="$t('recentlySold.title')">
			{{ $t('recentlySold.title') }}
		</LocalLink>
	</div>
</template>

<style>
.sold {
	position: absolute;
	color: white;
	top: 0.75rem;
	inset-inline-start: 0.75rem;
	padding: 0.25rem 0.75rem;
	border-radius: 10px;
	background: var(--blue);
}

.home-carousel-item {
	background: white;
	color: var(--black);
	border-radius: 20px;
	position: relative;
	max-width: 320px;
	overflow: hidden;
	margin: 0 auto;
}

.home-carousel-item-txt {
	padding: 0.5rem 1.5rem 1rem;
}

.home-carosel-small-txt {
	height: 59px;
	overflow: hidden;
}

.swiper {
	width: 100%;
	padding-top: 50px;
	padding-bottom: 50px;
	mask-image: linear-gradient(
		90deg,
		transparent 5%,
		#000 25%,
		#000 85%,
		transparent 95%
	) !important;
}

.swiper-slide {
	background-position: center;
	background-size: cover;
	width: 300px;
	height: 300px;
}

:root {
	--swiper-navigation-color: white;
	/* --swiper-navigation-top-offset: 100%; */
	/* --swiper-navigation-sides-offset: 0% */
}

.mySwiper .swiper-wrapper {
}

.swiper-button-next,
.swiper-button-prev {
}

.swi-ctrl {
	position: absolute;
	top: 48%;
	width: 100%;
	z-index: 99;
	display: flex;
	justify-content: space-between;
	padding: 0rem 4rem;
	height: 0;
}

.swi-ctrl img {
	height: 20px;
}

.swi-wrap {
	position: relative;
}
.carousel-img {
	background: #000;
}
.swi-prev,
.swi-next {
	background: var(--blue);
	height: 40px;
	width: 40px;
	border-radius: 100%;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
@media screen and (max-width: 900px) {
	.swiper {
		mask-image: unset !important;
	}
	:root {
		--swiper-navigation-color: transparent;
	}
	.swi-ctrl {
		padding: 0rem 0.5rem;
	}
}
</style>
